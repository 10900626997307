import {ACTION_TYPE} from '@bri/portnet-core';
import {useEffect, useRef} from 'react';
import {GoogleMap, Marker, Polyline, withGoogleMap, withScriptjs} from 'react-google-maps';
import {Spiderfy} from './Spiderfy';

type Props = {
  actions: Array<{type: ACTION_TYPE; coords: {lat: number; lng: number}}>;
};

const MyMapComponent = withScriptjs(
  withGoogleMap((props: Props) => {
    const map = useRef<GoogleMap | null>(null);

    const actionIconMap = {
      [ACTION_TYPE.WASTE_DECLARATION]: {
        icon: 'icon_ship',
        color: '#0075E2',
      },
      [ACTION_TYPE.SELECT_CONSIGNEE]: {
        icon: 'icon_handshake',
        color: '#5BB859',
      },
      [ACTION_TYPE.CONFIRM_CONSIGNEE]: {
        icon: 'icon_market',
        color: '#5BB859',
      },
      [ACTION_TYPE.ADD_WASTES]: {
        icon: 'icon_truck',
        color: '#797D8C',
      },
      [ACTION_TYPE.SWAP_WASTE]: {
        icon: 'icon_waste-basket',
        color: '#797D8C',
      },
    };

    useEffect(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      props.actions.forEach(action => bounds.extend(action.coords));
      map.current?.fitBounds(bounds);
    }, [map, props.actions]);

    const calculatePolyLineProperties = () => {
      const paths = [];
      let lastCoord;
      for (const action of props.actions) {
        if (lastCoord !== undefined && lastCoord !== action.coords) {
          paths.push({color: actionIconMap[action.type].color, path: [lastCoord, action.coords]});
        }
        lastCoord = action.coords;
      }
      return paths;
    };

    return (
      <GoogleMap
        ref={map}
        defaultZoom={15}
        defaultCenter={props.actions[0].coords}
        defaultOptions={{
          disableDefaultUI: true,
        }}>
        {calculatePolyLineProperties().map(properties => (
          <Polyline
            path={properties.path}
            options={{
              strokeWeight: 2,
              strokeColor: properties.color,
            }}
          />
        ))}
        <Spiderfy>
          {props.actions.map((action, index) => (
            <Marker
              key={index}
              position={action.coords}
              // options={{anchorPoint: }}
              icon={{
                url: require(`../assets/img/markers/${actionIconMap[action.type].icon}.png`),
                anchor: new google.maps.Point(21, 21),
              }}
            />
          ))}
        </Spiderfy>
      </GoogleMap>
    );
  })
);

export const WastePassMap: React.FC<Props> = props => (
  <MyMapComponent
    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDn7xyjUQProNe9euZgNZOmLZUDkU0n2cU"
    loadingElement={<div style={{height: `100%`}} />}
    containerElement={<div style={{height: 400}} />}
    mapElement={<div className="rounded-4 mb-3" style={{height: '100%', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}} />}
    {...props}
  />
);
