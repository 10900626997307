import React from 'react';

type Props = {
  text: string | number;
  fs?: number;
  color?: string;
  fw?: string;
  underlined?: boolean;
  style?: React.CSSProperties;
};

const CustomText: React.FC<Props> = ({text, fs, color, fw, underlined, style}) => (
  <>
    <p className={`${fw || null} my-0`} style={{fontSize: fs, color, textDecorationLine: underlined ? 'underline' : undefined, ...style}}>
      {text}
    </p>
  </>
);

export {CustomText};
