import React, {FC, useRef, useState} from 'react'
import {useLayout} from '../../core'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderUserMenu} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {currentUser, logout} = useAuth()
  const {config} = useLayout()
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState<string>('')

  const searchInputRef = useRef<any>(null)

  const handleSearchButton = () => {
    navigate('/wastepass/' + searchInput)
    searchInputRef.current.value = ''
  }

  const shadowStyles = {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  }

  return (
    <>
      <div className='d-flex box flex-row-fluid'>
        <div className='container-1 flex-row-fluid'>
            <input
              type='search -ms-clear { display: none; width : 0; height: 0; }'
              id='search'
              ref={searchInputRef}
              placeholder='Search...'
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleSearchButton()
                }
              }}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className='icon button-input bg-transparent border-0'
              type='button'
              data-kt-element='send'
              onClick={handleSearchButton}
            >
              <KTSVG
                path='/media/icons/svg-header/search-icon.svg'
                className={toolbarButtonIconSizeClass}
              />
            </button>
        </div>
      </div>
      <div className='d-flex align-items-stretch flex-shrink-0 gap-5'>
      <div className='d-flex flex-shrink-0 gap-5'></div>
        {/* CHAT -> Notifications
                replace ->
                      id='kt_drawer_chat_toggle'
                with the id of the notification toogle or page
             */}
        <div className={clsx('d-flex align-items-center')}>
          {/* begin::Menu wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
          >
            <KTSVG
              path='/media/icons/svg-header/notification.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center gap-3')}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              src={toAbsoluteUrl('/media/avatars/blue.jpg')}
              alt='metronic'
              className='rounded-circle'
            />
             <HeaderUserMenu/>
          </div>

          <div className='userInfo'>
            <p className='userInfo user-name'> {currentUser?.username} </p>
            <button
              className='icon button-input bg-transparent border-0'
              type='button'
              data-kt-element='send'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG path='/media/icons/svg-header/arrow_down.svg' className='svg-icon-0' />
              <HeaderUserMenu />
            </button>

            {/*<button type="button" className="btn btn-default btn-sm">
                          <span className="glyphicon glyphicon-triangle-bottom"/>
                      </button>
                      */}
          </div>

          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler
              {config.header.left === 'menu' && (
                  <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
                      <div
                          className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                          id='kt_header_menu_mobile_toggle'
                      >
                          <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1'/>
                      </div>
                  </div>
              )}
              */}
      </div>
    </>
  )
}

export {Topbar}
