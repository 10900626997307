import React from 'react';
import Icons from '../../assets/icons/icons.svg';

type Props = {
  name: string;
  color?: string;
  size: number;
  withoutCircle?: boolean;
};

const Icon: React.FC<Props> = ({name, color, size, withoutCircle}) => {
  const iconContent = (
    <svg className={`icon icon-${name}`} width={size} height={size}>
      <use xlinkHref={`${Icons}#icon-${name}`} width={size} height={size} />
    </svg>
  );

  return !withoutCircle ? (
    <div className="rounded-circle d-flex justify-content-center align-items-center" style={{width: 50, height: 50, backgroundColor: color}}>
      {iconContent}
    </div>
  ) : (
    iconContent
  );
};

export {Icon};
