import {ElasticSearchServiceTypes, ServiceFunction} from '@bri/portnet-core';
import BaseService from './BaseService';

export default class ElasticService {
  static search: ServiceFunction<ElasticSearchServiceTypes, 'search'> = params =>
    BaseService.new()
      .auth()
      .get('/v1/elasticsearch/search', params);

  static lastestWastesPass: ServiceFunction<ElasticSearchServiceTypes, 'lastestWastesPass'> = params =>
    BaseService.new()
      .auth()
      .get('/v1/elasticsearch/lastestWastesPass', params);
}
