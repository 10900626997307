/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Registration} from './components/Registration';
import {Login} from './components/Login';
import {toAbsoluteUrl} from '../../../_metronic/helpers';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid p-5 justify-content-center bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: `linear-gradient(#4FD1C5,#398DB1)`,
      }}>
      {/* begin::Content */}
      <div className="d-flex flex-column flex-lg-row flex-center p-10 pb-lg-20 w-100 w-lg-75 align-self-center">
        <div className="d-flex justify-content-center flex-column" style={{width: `60%`}}>
          <a href="#" className="mb-12 align-self-center w-100 w-lg-75">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/WastePass-03.svg')} className="w-100" />
          </a>
          <p className="align-self-center text-white fw-normal fs-2 w-100 w-lg-75">
            CIRCULARPORT is a new blockchain platform to create a collaborative framework for effective waste management for greener and efficient ports around the world. We
            digitalize the whole process and create a Peer-to-Peer validation, where each stakeholder must validate the transaction during the process with their own digital
            identity
          </p>
        </div>
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-transparent rounded p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export {AuthPage};
