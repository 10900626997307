/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {WastePassportType} from '@bri/portnet-core';
import QRCode from 'react-qr-code';
import {H2} from './Atoms/H2';

type Props = {
  content: WastePassportType;
};

const Passport: React.FC<Props> = ({content}) => (
  <div>
    <div className="border-0 d-flex justify-content-center mt-20">
      <H2 text={`WASTEPASS ${content.id}`} />
    </div>
    <div className="border-0 d-flex justify-content-center mt-15">
      <img src={require('../assets/img/WastePass-02.png')} className="w-50" />
    </div>
    <div className="border-0 d-flex justify-content-center mt-8">
      <H2 text={content.callSignal} />
    </div>
    <div className="border-0 d-flex justify-content-center mt-2">
      <div style={{height: 'auto', margin: '0 auto', maxWidth: 100, width: '100%'}}>
        <QRCode size={256} bgColor="transparent" style={{height: 'auto', maxWidth: '100%', width: '100%'}} value={window.location.href} viewBox="0 0 256 256" />
      </div>
    </div>
  </div>
);

export {Passport};
