import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {LayoutSplashScreen} from '../../../../_metronic/layout/core';
import {AuthModel, UserModel} from './_models';
import * as authHelper from './AuthHelpers';
import {WithChildren} from '../../../../_metronic/helpers';
import AuthService from '../../../services/AuthService';
import BaseService from '../../../services/BaseService';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  loadedUser: boolean | undefined;
  setLoadedUser: Dispatch<SetStateAction<boolean | undefined>>;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  loadedUser: false,
  setLoadedUser: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => useContext(AuthContext);

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [loadedUser, setLoadedUser] = useState<boolean | undefined>(false);
  const saveAuth = (authModel: AuthModel | undefined) => {
    setAuth(authModel);
    if (authModel) {
      authHelper.setAuth(authModel);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, loadedUser, setLoadedUser}}>{children}</AuthContext.Provider>;
};

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setLoadedUser} = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          BaseService.setAuthToken(apiToken);
          AuthService.me()
            .response(res => {
              if (res.user) {
                setCurrentUser(res.user);
                setLoadedUser(true);
              }
            })
            .error(err => {
              if (err.code === 401) {
                logout();
              }
            });
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => {
        didRequest.current = true;
      };
    };

    if (auth && auth.token) {
      requestUser(auth.token).then();
    } else {
      logout();
      setShowSplashScreen(false);
      setLoadedUser(true);
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export {AuthProvider, AuthInit, useAuth};
