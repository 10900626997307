/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {ACTION_TYPE, ActionType} from '@bri/portnet-core';
import moment from 'moment';
import {Icon} from './Molecules/Icon';
import {CustomText} from './Atoms/CustomText';

type Props = {
  content: ActionType[];
};

function selectoInfo(i: ActionType) {
  let color = '#797D8C';
  let icon = '';
  let text = '';
  switch (i.action) {
    case ACTION_TYPE.WASTE_DECLARATION:
      color = '#0075E2';
      icon = 'ship-solid';
      text = 'Waste declaration';
      break;
    case ACTION_TYPE.SELECT_CONSIGNEE:
      color = '#5BB859';
      icon = 'handshake-solid';
      text = 'Waste management agreement';
      break;
    case ACTION_TYPE.ADD_WASTES:
      color = '#797D8C';
      icon = 'truck-solid';
      text = `Waste collected: ${i.amount} ${i.type}`;
      break;
  }

  return {
    color,
    icon,
    text,
  };
}

const TimeLineWP: React.FC<Props> = ({content}) => (
  <>
    {content.map((i, n = 0) => {
      const info = selectoInfo(i);
      const urlBlockExplorer = `https://explorer.circulartrust.blueroominnovation.com/tx/${i.txHash}`;

      return (
        <div className="row mb-5 justify-content-center align-items-center" key={n + 1}>
          <div className="col-xxl-2">
            <Icon name={info.icon} color={info.color} size={35} />
          </div>
          <div className="col-xxl-10">
            <CustomText text={`${moment(i.date).format('MMMM Do YYYY, HH:mm:ss')}`} color="#797D8C" fs={10} />
            <CustomText text={info.text} color="#797D8C" fs={12} />
            <a href={urlBlockExplorer} target="_blank" rel="noreferrer">
              <CustomText text="Transaction secured" color="#0075E2" fw="font-weight-semibold" fs={12} underlined style={{display: 'inline', marginRight: 3}} />
              <Icon name="secured" color={info.color} withoutCircle size={12} />
            </a>
          </div>
        </div>
      );
    })}
  </>
);

export {TimeLineWP};
