const Configuration = {
  // Server URL
  serverUrl: 'https://portnet.blueroominnovation.com',
  // Redux Store Persisted
  persistStore: false,
  // Shor more error logs in error screen
  showErrorLogs: false,
  // Release name
  releaseName: 'PRE',
};

// Export configuration
export default Configuration;
