import React from 'react';
import useModal from './useModal';
import Modal from '../Components/Modal';

// eslint-disable-next-line import/no-mutable-exports
let ModalContext: React.Context<any>;
const {Provider} = (ModalContext = React.createContext({modal: false, handleModal: (content?: {title: ''; content: ''}) => {}, modalContent: {title: '', content: ''}}));

const ModalProvider = ({children}: any) => {
  const {modal, handleModal, modalContent} = useModal();
  return (
    <Provider value={{modal, handleModal, modalContent}}>
      <Modal />
      {children}
    </Provider>
  );
};

export {ModalContext, ModalProvider};
