import React from 'react';
import {ERC20sType, WastePassportType} from '@bri/portnet-core';
import {useNavigate} from 'react-router-dom';
import moment from 'moment/moment';
import {Card} from '../Card';
import {CustomText} from '../Atoms/CustomText';

type Props = {
  passport: WastePassportType;
};

const LastWastePassportItem: React.FC<Props> = ({passport}) => {
  // const passportURL = Configuration.baseUrl + '/wastepass/' + passport.id;
  const passportURLaux = window.location.href.split('/');
  const passportURL = `${passportURLaux.slice(0, -2).join('/')}/wastepass/${passport.id}`;

  function findMintDate(array: {actions: any[]}) {
    return array.actions.find((element: {action: string}) => element.action === 'WASTE_DECLARATION');
  }

  const minDateOfPassport = findMintDate(passport);

  function getListOfWastes(tableInfo: ERC20sType[]) {
    let list = '';
    tableInfo.map(i => {
      list = `${list + i.type}; `;
    });
    return list;
  }

  const listOfWastes = getListOfWastes(passport.erc20s);

  const navigate = useNavigate();
  const handleInspectPassButton = (id: number) => (event: React.MouseEvent) => {
    navigate(`/wastepass/${id}`);
    event.preventDefault();
  };

  return (
    <>
      <Card>
        <div className="row d-flex">
          {/* Image of WastePassport */}
          <div className="col-1 d-flex justify-content-center align-items-center">
            <img src={require('../../assets/img/WastePass-03.png')} style={{width: 30}} />
          </div>

          {/* Mint Date of WastePassport */}
          <div className="col-4 col-lg-2 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div>
                <CustomText text={`${moment(minDateOfPassport.date).format('HH:mm DD-MM-YYYY')}`} color="#797D8C" fs={14} />
              </div>
            </div>
          </div>

          {/* CallSignal of WastePassport */}
          <div className="col-2 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div>
                <CustomText text={passport.callSignal} color="#04103B" fs={16} fw="fw-bold" />
              </div>
            </div>
          </div>

          {/* Erc20s (wastes) of WastePassport */}
          <div className="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div>
                <CustomText text={listOfWastes} color="#797D8C" fs={13} />
              </div>
            </div>
          </div>

          {/* Link to WastePassport */}
          <div className="col-3 d-none d-lg-flex align-items-center justify-content-center">
            <div className="text-center">
              <div onClick={handleInspectPassButton(passport.id)}>
                <a href={passportURL}>
                  <p
                    className="fw-normal  m-0"
                    style={{
                      color: '#0075E2',
                      textDecoration: 'underline',
                      fontSize: '14px',
                    }}>
                    {passportURL}
                  </p>
                </a>
              </div>
            </div>
          </div>

          {/* Link Icon to WastePassport */}
          <div className="col-1 d-flex align-items-center justify-content-center">
            <a href={passportURL}>
              <img src={require('../../assets/img/bi_eye-fill.png')} onClick={handleInspectPassButton(passport.id)} />
            </a>
          </div>
        </div>
      </Card>
    </>
  );
};

export {LastWastePassportItem};
