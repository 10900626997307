import React from 'react';

type Props = {
  text: string;
};

const CardTitle: React.FC<Props> = ({text}) => (
  <>
    <span className="card-title font-weight-extrabold text-primary fs-3 mt-5">{text}</span>
  </>
);

export {CardTitle};
