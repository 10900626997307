import React from 'react';

type Props = {
  text: string | number;
};

const Text: React.FC<Props> = ({text}) => (
  <>
    <p className="text-muted fw-normal  m-0">{text}</p>
  </>
);

export {Text};
