import React from 'react';

type Props = {
  text: string;
  fs: number;
  color?: string;
  fw?: string;
};

const CustomTitle: React.FC<Props> = ({text, fs, color, fw}) => (
  <>
    <p className={`${fw || null} font-weight-bold mb-5`} style={{fontSize: fs, color}}>
      {text}
    </p>
  </>
);

export {CustomTitle};
