/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import AuthService from '../../../services/AuthService';
import {ModalType} from '../../../types/types';
import {ModalContext} from '../../../Hooks/modalContext';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();

  const {handleModal} = React.useContext(ModalContext);
  const modalInfo: ModalType = {
    title: 'Invalid Credentials',
    content: ``,
    actionText: 'Close',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true);
      setLoading(true);
      AuthService.login({username: values.email, password: values.password})
        .response(res => {
          console.log('R', res);
          if (res.status === 'VALID' /* ACCESS_TOKEN_STATUS.VALID */ && res.access_token && res.user) {
            setCurrentUser({username: res.user.username});
            saveAuth({id: res.user._id, token: res.access_token});
          }
          setLoading(false);
          setSubmitting(false);
        })
        .error(err => {
          handleModal(modalInfo);
          console.log('ERR', err);
          setLoading(false);
          setSubmitting(false);
        });
      /* try {
              const {data: auth} = await login(values.email, values.password)
              saveAuth(auth)
              const {data: user} = await getUserByToken(auth.api_token)
              setCurrentUser(user)
            } catch (error) {
              console.error(error)
              saveAuth(undefined)
              setStatus('The login detail is incorrect')
              setSubmitting(false)
              setLoading(false)
            } */
    },
  });

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-1 font-weight-extrabold text-dark">Email or Username</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
          style={{
            boxShadow: `0 4px 4px 0 rgba(0,0,0,0.16)`,
            borderColor: `#4880FF50`,
          }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label font-weight-extrabold text-dark fs-1 mb-0">Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            ' form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          style={{
            boxShadow: `0 4px 4px 0 rgba(0,0,0,0.16)`,
            borderColor: `#4880FF50`,
            borderWidth: `1px`,
          }}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
        <div className="p-3">
          <Link to="/login/forgot-password" className="link-primary fs-6 fw-light text-white">
            Did you forget your password?
          </Link>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-end">
        <button
          ref={el => el && el.style.setProperty(`box-shadow`, `0 4px 4px 0 rgba(0,0,0,0.16)`, 'important')}
          style={{
            padding: `1% 10%`,
            border: `1px solid #E2DEF9`,
          }}
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary bg-white text-dark mb-5 fw-bolder shadow"
          disabled={formik.isSubmitting || !formik.isValid}>
          {!loading && <span className="font-weight-extrabold indicator-label ">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{display: 'block'}}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
