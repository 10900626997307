/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {WastePassportType} from '@bri/portnet-core';
import {H3} from './Atoms/H3';
import {Text} from './Atoms/Text';

type Props = {
  content: WastePassportType;
};

const InfoWP: React.FC<Props> = ({content}) => (
  <>
    <div className="mb-5">
      <H3 text="IMO" />
      <Text text={content.IMO} />
    </div>
    <div className="mb-5">
      <H3 text="NAME" />
      <Text text={content.name} />
    </div>
    <div className="mb-5">
      <H3 text="SHIP TYPE" />
      <Text text={content.shipType} />
    </div>
    <H3 text="SHIP AGENT" />
    <Text text={content.shipAgent} />
  </>
);

export {InfoWP};
