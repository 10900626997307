// First load base configuration file
import BaseConfiguration from './config.base';

// now load the dynamic config file
let LocalConfiguration = null;
try {
  const config = process.env.REACT_APP_CONFIG || 'local';
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
  LocalConfiguration = require(`./config.${config}`).default;
  // LocalConfiguration = require(`./config.${process.env.NODE_ENV}`).default;
} catch (ignored) {
  LocalConfiguration = {};
}

const Config: typeof BaseConfiguration = {
  ...BaseConfiguration,
  ...LocalConfiguration,
};

export type ConfigType = typeof BaseConfiguration;

export default Config;
