import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import FormData from 'form-data';
import {ApiResponse, BaseServiceInterface, CLIENT_ERROR_TYPES, ServiceDefinition} from '@bri/portnet-core';
import Config from '../config/config';
import Configuration from '../config/config.base';

export enum SCREENS {
  Error = 'Error',
}

export enum ERROR_TYPE {
  GENERIC = 'GENERIC',
  NOT_FOUND = 'NOT_FOUND',
}

type AxiosMethods = 'POST' | 'GET';

type CustomAxiosError = {
  status: number;
  statusText: string;
  data: any;
};

/**
 * The Global Vars
 */

// Set up API params
export const ApiPath = '/api';
export const SocketPath = '/socket';

let accessToken = '';

/**
 * Each petition is a new instance of this class
 */
class BaseService<D extends ServiceDefinition, A extends keyof D> implements BaseServiceInterface<D, A> {
  needAuth = false;
  autoRedirect = true;
  petition?: Promise<any>;

  onApiResponse: any;
  onResponse: any;
  onError: any;

  auth(): BaseService<D, A> {
    this.needAuth = true;
    return this;
  }

  noRedirect(): BaseService<D, A> {
    this.autoRedirect = false;
    return this;
  }

  get<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('GET', url, params);
    return this;
  }

  post<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('POST', url, params);
    return this;
  }

  postFile<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    const formData = new FormData();
    if (params) {
      for (const field of Object.keys(params)) {
        if (field === 'file') {
          formData.append(field, params.file);
        } else {
          formData.append(field, params[field]);
        }
      }
    }

    //  let headers=formData.getHeaders();
    const options = {headers: {'Content-Type': 'multipart/form-data'}};
    this.petition = this.makePetition<T>('POST', url, formData, options);
    return this;
  }

  async axiosCall<T>(method: AxiosMethods, url: string, params: any, config: AxiosRequestConfig): Promise<AxiosResponse<T> | CustomAxiosError> {
    try {
      let response: AxiosResponse<T>;
      switch (method) {
        case 'POST':
          response = await axios.post<T>(`${Config.serverUrl}${url}`, params, config);
          break;
        case 'GET':
          response = await axios.get<T>(`${Config.serverUrl}${url}`, config);
          break;
      }
      return response;
    } catch (error) {
      if ((error as any).response) {
        return (error as any).response;
      }
      return {
        data: error,
        status: 600,
        statusText: 'ERR_CONNECTION_REFUSED',
      };
    }
  }

  async makePetition<T extends ApiResponse>(method: AxiosMethods, url: string, params?: any, configp?: AxiosRequestConfig): Promise<T> {
    // Default options values
    let fullUrl = this.getFullUrl(url);

    const config = configp || {};
    config.headers = config.headers ? config.headers : {};
    config.headers['api-key'] = await this.buildApiKeyHeader();
    config.headers.Authorization = this.needAuth ? await this.buildAuthHeader() : false;

    if (method === 'GET' && params) {
      fullUrl += this.encodeQueryData(params);
    }

    // Do the petition
    const apiResponse = await this.axiosCall<T>(method, fullUrl, params, config);

    // Check for HTML errors
    if (apiResponse.status === 401) {
      // Unauthorized
      // TODO
      // store.dispatch(setAccessTokenInfo(ACCESS_TOKEN_STATUS.INVALID));
      // TODO redirect to login
      this.errorRedirect(ERROR_TYPE.GENERIC, 'Unauthorized user!');
    } else if (apiResponse.status === 501) {
      // Server api key invalid
      this.errorRedirect(ERROR_TYPE.GENERIC, 'Server api key invalid');
    } else if (apiResponse.status === 600) {
      // Server not running
      this.errorRedirect(ERROR_TYPE.GENERIC, 'Server not running', JSON.stringify(apiResponse));
    } else if (apiResponse.status >= 400 && apiResponse.status < 500 && apiResponse.status !== 422) {
      // 4XX error
      if (apiResponse.status === 404) {
        this.errorRedirect(ERROR_TYPE.NOT_FOUND, 'Not found error');
      } else {
        this.errorRedirect(ERROR_TYPE.GENERIC, `${apiResponse.status} Error`);
      }
    } else if (apiResponse.status >= 500) {
      // Internal server error
      this.errorRedirect(ERROR_TYPE.GENERIC, 'Internal server error');
    }

    // Check Api Response not empty
    if (!apiResponse.data) {
      this.errorRedirect(ERROR_TYPE.GENERIC, 'Empty server response');
    }

    // Check for APP errors
    if (apiResponse.data && apiResponse.data.error) {
      if (apiResponse.data.error.type === CLIENT_ERROR_TYPES.INTERNAL) {
        // Internal server error
        this.errorRedirect(ERROR_TYPE.GENERIC, 'Internal server error', JSON.stringify({error: apiResponse.data.error, request: apiResponse.data.request}));
      }
    }

    return apiResponse.data;
  }

  async buildApiKeyHeader(): Promise<string> {
    return Configuration.apiKey;
  }

  async buildAuthHeader(): Promise<string> {
    return `Bearer ${accessToken}`;
  }

  getFullUrl(url: string): string {
    let fullUrl = url;

    // Must start with /
    if (!fullUrl.startsWith('/')) {
      fullUrl = `/${fullUrl}`;
    }

    // Must start with /api
    if (!fullUrl.startsWith('/api')) {
      fullUrl = `/api${fullUrl}`;
    }

    // Auth must start with /api/secure
    if (this.needAuth) {
      if (!fullUrl.startsWith('/api/secure')) {
        fullUrl = `/api/secure${fullUrl.slice(4)}`;
      }
    }
    // No auth must not have /secure
    else if (fullUrl.startsWith('/api/secure')) {
      fullUrl = `/api${fullUrl.slice(11)}`;
    }
    return fullUrl;
  }

  errorRedirect(errorType: ERROR_TYPE, text: string, message?: string) {
    if (this.autoRedirect) {
      // TODO
      // store.dispatch(showErrorPage(errorType, text, Config.showErrorLogs ? message : undefined));
      throw new Error();
    }
  }

  encodeQueryData(params: any): string {
    const ret = [];
    for (const d in params) {
      if (d) {
        if (typeof params[d] === 'object' && typeof params[d].length) {
          // Is an array param
          for (const item of params[d]) {
            ret.push(`${encodeURIComponent(d)}[]=${item}`);
          }
        } else if (params[d]) {
          ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(params[d])}`);
        }
      }
    }
    return `?${ret.join('&')}`;
  }

  /**
   * Error and Response
   */
  apiResponse(onApiResponse: any) {
    this.onApiResponse = onApiResponse;
    return this;
  }

  error(onError: any) {
    this.onError = onError;
    this.execute();
    return this;
  }

  response(onResponse: any) {
    this.onResponse = onResponse;
    this.execute();
    return this;
  }

  /**
   * Execute the call
   */
  execute(force?: boolean) {
    if (force || (this.onError && this.onResponse)) {
      Promise.resolve(this.petition)
        .then(async apiResponse => {
          if (this.onApiResponse) {
            await this.onApiResponse(apiResponse);
          }
          if (apiResponse.error) {
            if (this.onError) await this.onError(apiResponse.error, apiResponse.request);
          } else if (apiResponse.response) {
            if (this.onResponse) await this.onResponse(apiResponse.response);
          } else {
            console.error('Empty data from server response');
          }
        })
        .catch(ignored => {
          console.error(ignored);
        });
    }
  }
}

export default {
  new<D extends ServiceDefinition, A extends keyof D>(): BaseService<D, A> {
    return new BaseService();
  },
  setAuthToken(token: string) {
    accessToken = token;
  },
};
