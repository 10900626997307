/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {WastePassportType} from '@bri/portnet-core';
import {CustomTitle} from './Atoms/CustomTitle';
import {LastWastePassportItem} from './Molecules/LastWastePassportItem';

type Props = {
  content: WastePassportType[];
};

const LastestWastesPassports: React.FC<Props> = ({content}) => (
  <div className="row mt-6">
    <div className="col-12 ">
      <CustomTitle text="Latest Waste Collected" fs={16} fw="mb-6" />
      {content.map(i => (
        <LastWastePassportItem passport={i} key={i.id} />
      ))}
    </div>
  </div>
);

export {LastestWastesPassports};
