import React from 'react';
import ReactDOM from 'react-dom';
import {ModalContext} from '../Hooks/modalContext';
import {CustomTitle} from './Atoms/CustomTitle';
import {CustomText} from './Atoms/CustomText';

const Modal = () => {
  const {modalContent, handleModal, modal} = React.useContext(ModalContext);

  if (modal) {
    return ReactDOM.createPortal(
      <div className="position-fixed h-100 w-100 d-flex align-items-center justify-content-center row" style={{background: 'rgba(0,0,0,0.4)', backdropFilter: 'blur(3px)'}}>
        <div className="col-xl-3 col-10 bg-white shadow-lg rounded text-center px-20">
          <div className="pt-10 pb-5">
            <CustomTitle text={modalContent.title} fs={32} />
          </div>
          <div>
            <CustomText text={modalContent.content} fs={20} color="#797D8C" />
          </div>
          {modalContent.actionText ? (
            <button
              className="my-10 border-0 rounded px-16 py-1 text-white"
              style={{fontSize: 20, background: 'linear-gradient(#4FD1C5, #3A8FB2)'}}
              onClick={() => handleModal()}>
              {modalContent.actionText}
            </button>
          ) : null}
        </div>
      </div>,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('#root-modals')
    );
  }
  return null;
};

export default Modal;
