import React from 'react';

type Props = {
  text: string;
};

const H3: React.FC<Props> = ({text}) => (
  <>
    <h3 className="fs-5 font-weight-extrabold m-0 mb-1">{text}</h3>
  </>
);

export {H3};
