import React from 'react';
import {CardTitle} from './Atoms/CardTitle';

type Props = {
  title?: string;
  children: React.ReactChild;
};

const Card: React.FC<Props> = ({title, children}) => (
  <div className="card mb-xl-8 mb-5">
    {title ? <CardTitle text={title} /> : null}
    <div className="card-body">{children}</div>
  </div>
);
export {Card};
