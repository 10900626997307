import React from 'react';
import {ModalType} from '../types/types';

export default () => {
  const [modal, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<ModalType>({title: '', content: '', actionText: ''});
  const handleModal = (content: ModalType = {title: '', content: '', actionText: ''}) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return {modal, handleModal, modalContent};
};
