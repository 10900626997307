import React, {ReactElement} from 'react';
import PropTypes from 'prop-types';
import {MAP, MARKER} from 'react-google-maps/lib/constants';

type Props = {
  onSpiderClick?: (e: any) => void;
  children: ReactElement[];
};

const Spiderfy: React.FC<Props> = (props, context: any) => {
  const omsReq = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
  const oms = new omsReq.OverlappingMarkerSpiderfier(context[MAP], {
    markersWontMove: true,
    basicFormatEvents: true,
    keepSpiderfied: true,
    circleFootSeparation: 46,
    spiralFootSeparation: 52,
  });
  // markerNodeMounted = markerNodeMounted.bind(this);

  const markerNodeMounted = (ref: any) => {
    if (!ref) return;
    const marker = ref.state[MARKER];
    oms.addMarker(marker);
    window.google.maps.event.addListener(marker, 'spider_click', (e: any) => {
      if (props.onSpiderClick) props.onSpiderClick(e);
    });
  };

  return <>{React.Children.map(props.children, (child: ReactElement) => React.cloneElement(child, {ref: markerNodeMounted}))}</>;
};

Spiderfy.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  [MAP]: PropTypes.object,
};

export {Spiderfy};
