/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/svg-menu/Fill1.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/wastepass'
        icon='/media/icons/svg-menu/maki_waste-basket.svg'
        title='Waste Traceability'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/healthicons_market-stall.svg'
        title='Product Passports'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/Group.svg'
        title='Waste Revalorization'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/emojione-monotone_recycling-symbol.svg'
        title='Converted Categories'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/mdi_molecule-co2.svg'
        title='Co2 Bounce'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/ic_sharp-fmd-bad.svg'
        title='Bottlenecks Detected'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to=''
        icon='/media/icons/svg-menu/icon-park_smart-optimization.svg'
        title='Number of Optimizations'
        fontIcon='bi-layers'
      />

      {/**
         EXEMPLE DE ITEMS AMB DROPDOWN I SEPARADORS
         */}
      {/*
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      */}
    </>
  )
}
