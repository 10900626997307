/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {SimpleWastePassportType} from '@bri/portnet-core';
import {CustomTitle} from './Atoms/CustomTitle';
import {ProductPassportGeneratedItem} from './Molecules/ProductPassportGeneratedItem';

type Props = {
  content: SimpleWastePassportType[];
};

const ProductPassportsGenerated: React.FC<Props> = ({content}) => (
  <div className="row mt-6">
    <div className="col-12 mb-6">
      <CustomTitle text="Product Passport Generated" fs={16} />
      {content.map(i => (
        <ProductPassportGeneratedItem passport={i} key={i.id} />
      ))}
    </div>
  </div>
);

export {ProductPassportsGenerated};
