import React from 'react';
import background from '../assets/img/WastePass-03.png';
import {WithChildren} from '../../_metronic/helpers';

const PassportCard: React.FC<WithChildren> = ({children}) => (
  <div
    className="bgi-no-repeat rounded mb-8"
    style={{
      backgroundImage: `url(${background})`,
      height: 471,
      width: 346,
      boxShadow: '10px 10px 15px 0px rgba(0, 0, 0, 0.25)',
    }}>
    {children}
  </div>
);
export {PassportCard};
