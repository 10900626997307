import React from 'react';

type Props = {
  text: string;
};

const H1: React.FC<Props> = ({text}) => (
  <>
    <h2 className="font-weight-bolder" style={{fontSize: 32}}>
      {text}
    </h2>
  </>
);

export {H1};
