import {ServiceFunction} from '@bri/portnet-core';
import BaseService from './BaseService';

export default class AuthService {
  static login: ServiceFunction<any, 'login'> = params => BaseService.new().post('/v1/auth/login', params);

  static register: ServiceFunction<any, 'register'> = params => BaseService.new().post('v1/users/create', params);

  static me: ServiceFunction<any, 'me'> = params =>
    BaseService.new()
      .noRedirect()
      .auth()
      .get('/v1/auth/me', params);
}
