import React from 'react';
import {SimpleWastePassportType} from '@bri/portnet-core';
import {useNavigate} from 'react-router-dom';
import {Card} from '../Card';
import {CustomText} from '../Atoms/CustomText';
import {Text} from '../Atoms/Text';

type Props = {
  passport: SimpleWastePassportType;
};

const ProductPassportGeneratedItem: React.FC<Props> = ({passport}) => {
  // const passportURL = Configuration.baseUrl + '/wastepass/' + passport.id;
  const passportURLaux = window.location.href.split('/');
  const passportURL = `${passportURLaux.slice(0, -2).join('/')}/wastepass/${passport.id}`;

  const navigate = useNavigate();
  const handleInspectPassButton = (id: number) => (event: React.MouseEvent) => {
    navigate(`/wastepass/${id}`);
    event.preventDefault();
  };

  return (
    <>
      <Card>
        <div className="row d-flex">
          <div className="col-1 p-0 d-flex justify-content-center">
            <img src={require('../../assets/img/WastePass-03.png')} style={{width: 30}} />
          </div>
          <div className="col-5 p-0 d-flex align-items-center justify-content-center">
            <div className="text-center">
              <div>
                <CustomText text="Token ID" fw="font-weight-bold" />
              </div>
              <div>
                <Text text={passport.id} />
              </div>
            </div>
          </div>
          <div className="col-4 p-0 d-flex align-items-center d-none d-lg-flex justify-content-center">
            <div className="text-center">
              <div>
                <CustomText text="Public URL" fw="font-weight-bold" />
              </div>
              <div onClick={handleInspectPassButton(passport.id)}>
                <a href={passportURL}>
                  <Text text={passportURL} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-2 p-0 d-flex align-items-center justify-content-end">
            <a href={passportURL}>
              <img src={require('../../assets/img/bi_eye-fill.png')} onClick={handleInspectPassButton(passport.id)} />
            </a>
          </div>
        </div>
      </Card>
    </>
  );
};

export {ProductPassportGeneratedItem};
