import React from 'react';

type Props = {
  text: string;
};

const H2: React.FC<Props> = ({text}) => (
  <>
    <h2 className="fs-3 font-weight-extrabold">{text}</h2>
  </>
);

export {H2};
