import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {WastePassportType} from '@bri/portnet-core';
import {ModalContext} from '../../Hooks/modalContext';
import {ModalType} from '../../types/types';
import ElasticService from '../../services/ElasticService';
import {LastestWastesPassports} from '../../Components/LastestWastesPassports';
import {H1} from '../../Components/Atoms/H1';

const Dashboard: FC = () => {
  const [howMany, setHowMany] = useState(5);

  const [wastePassports, setWastePassports] = useState<WastePassportType[] | undefined>(undefined);

  const {handleModal} = React.useContext(ModalContext);

  const modalInfo: ModalType = {
    title: 'No WastePassport has been created yet',
    content: '',
  };

  useEffect(() => {
    if (howMany) {
      setWastePassports(undefined);
      const params = {size: howMany} ? {size: howMany} : {size: 5};
      ElasticService.lastestWastesPass(params)
        .apiResponse(res => {
          if (!res.response) {
            handleModal(modalInfo);
          }
        })
        .response(res => {
          setWastePassports(res);
        })
        .error(err => console.log(err));
    }
  }, [howMany]);

  return (
    <>
      {wastePassports ? (
        <div className="ms-0 ms-lg-5 ms-xl-2 row d-flex">
          <div className="mx-0 mb-6 p-0">
            <H1 text="Overview" />
          </div>
          <div className="col-xxl-8 w-100 p-0">
            <div className="row">
              <div className="col-md-12">
                <LastestWastesPassports content={wastePassports!} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export {Dashboard};
