import React from 'react';
import {ERC20sType} from '@bri/portnet-core';
import data from '../../wastesButtonsColors.json';

type Props = {
  tableInfo: ERC20sType[];
};

const ERC20infoWP: React.FC<Props> = ({tableInfo}) => {
  const buttonsWithColor = tableInfo.map(table => ({
    ...table,
    color: data.find(d => d.id === table.type), // relation
  }));

  const myButtons = buttonsWithColor.map(j => (
    <button
      key={j.type}
      style={{
        color: 'white',
        background: j.color?.color || '#939393',
        border: 'white',
        margin: '0.3em 0.3em',
        borderRadius: '1em',
        fontWeight: '600',
        fontSize: '14px',
        padding: '10px',
        display: 'inline-block',
        width: 'auto',
        height: '55px',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        maxWidth: '200px',
        minWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'default',
      }}>
      {`${j.value}m3`}
      <br />
      {`${j.type.toUpperCase()}`}
    </button>
  ));

  return (
    <>
      <div
        id="container"
        style={{
          position: 'relative',
          width: '100%',
          background: 'transparent',
          justifyContent: 'center',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '1fr 1fr',
        }}>
        {myButtons}
      </div>
    </>
  );
};

export {ERC20infoWP};
