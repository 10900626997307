/* eslint-disable jsx-a11y/anchor-is-valid */
import {WastePassportType} from '@bri/portnet-core';
import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {H1} from '../../Components/Atoms/H1';
import {H3} from '../../Components/Atoms/H3';
import {Card} from '../../Components/Card';
import {ERC20infoWP} from '../../Components/ERC20infoWP';
import {InfoWP} from '../../Components/InfoWP';
import {Passport} from '../../Components/Passport';
import {PassportCard} from '../../Components/PassportCard';
import {ProductPassportsGenerated} from '../../Components/ProductPassportsGenerated';
import {TimeLineWP} from '../../Components/TimeLineWP';
import {WastePassMap} from '../../Components/WastePassMap';
import ElasticService from '../../services/ElasticService';
import {ModalContext} from '../../Hooks/modalContext';
import {ModalType} from '../../types/types';

const WastePass: FC = () => {
  const {searchId} = useParams();
  const navigate = useNavigate();
  const [wastePassport, setWastePassport] = useState<WastePassportType | undefined>(undefined);

  const {handleModal} = React.useContext(ModalContext);

  const modalInfo: ModalType = {
    title: 'WastePassport not found!',
    content: `WastePassport with ID ${searchId} could not been found. Please check the ID number. In case your ID don't appear please contact us`,
    actionText: 'Close',
  };

  useEffect(() => {
    if (searchId) {
      setWastePassport(undefined);
      const params = isNaN(parseInt(searchId)) ? {callSignal: searchId} : {tokenId: parseInt(searchId)};
      ElasticService.search(params)
        .apiResponse(res => {
          if (!res.response) {
            handleModal(modalInfo);
            navigate('/dashboard/');
          }
        })
        .response(res => {
          setWastePassport(res);
        })
        .error(err => console.log(err));
    }
  }, [searchId]);
  return (
    <>
      {wastePassport ? (
        <div className="row">
          <div className="mb-5" style={{paddingLeft: '3em'}}>
            <H1 text="WastePass" />
          </div>
          <div className="col-xxl-8">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <PassportCard>
                  <Passport content={wastePassport} />
                </PassportCard>
              </div>
              <div className="col-md-6">
                <Card>
                  <InfoWP content={wastePassport} />
                </Card>
                <ERC20infoWP tableInfo={wastePassport.erc20s} />
              </div>
            </div>
            <div className="row ps-sm-5">
              <div className="col-md-12">
                <ProductPassportsGenerated content={wastePassport.generatedDigitalTwins} />
              </div>
            </div>
          </div>
          <div className="col-xxl-4">
            <Card>
              <div>
                <div className="mb-10">
                  <H3 text="Waste Traceability" />
                </div>
                <WastePassMap
                  // center={wastePassport.actions.length ? wastePassport.actions[0].coords : { lat: 0, lng: 0 }}
                  actions={wastePassport.actions.map(action => ({
                    type: action.action,
                    coords: action.coords,
                  }))}
                />
              </div>
            </Card>
            <Card>
              <>
                <div className="mb-10">
                  <H3 text="Latest updates" />
                </div>
                <TimeLineWP content={wastePassport.actions} />
              </>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  );
};

export {WastePass};
