const Configuration = {
  // Server URL
  serverUrl: 'http://localhost:8080',

  baseUrl: 'http://localhost:3011',
  // Redux Store Persisted
  persistStore: false,
  // Shor more error logs in error screen
  showErrorLogs: false,
  // Release name
  releaseName: 'Local',

  apiKey: 'bri9aht9kdyuz0cjmaszgacj',
};

// Export configuration
export default Configuration;
